/* eslint-disable */
export const getMessages = (locale: string) => {
    try {
        return (window as any)._newLocales.Profile[locale];
    } catch (e) {
        // TODO: send errors to sentry or handle them somehow another way.
    }
    switch(locale) {
        case "es_ES":
            return import("./compiled/es_ES-Profile.json");
        case "fr_FR":
            return import("./compiled/fr_FR-Profile.json");
        case "ru_RU":
            return import("./compiled/ru_RU-Profile.json");
        case "vi_VN":
            return import("./compiled/vi_VN-Profile.json");
        case "zh_CN":
            return import("./compiled/zh_CN-Profile.json");
        default: 
            return {};
    }
}

export const getSupportedLocales = (): string[] => [
    "es_ES",
    "fr_FR",
    "ru_RU",
    "vi_VN",
    "zh_CN",
];
